import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { layouts } from '@/utils/preset'
import { desktop, laptop, tabletL, tabletS, mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Typography from '@/components/common/Typography'
import ParticleBackground from '@/components/common/ParticleBackground'
import LeadButton1 from './LeadButton1'
import LeadButton2 from './LeadButton2'

const ContactLead = props => (
  <StaticQuery
    query={query}
    render={ data => {
      const list = data.list.edges.map(el => el.node)
      return (
        <Root theme={props.theme}>
          <Background scale={400} />
          <Container>
            <Head>
              <Title variant="h3" lang="en" component="h2">CONTACT</Title>
              <Body variant="body1">ウィザードでは企画から設計、運用まで様々なシーンに沿った技術でサポートいたします。内容が固まっていなくても構いません。「こんなことできる？」と、まずはお気軽にご相談ください。</Body>
            </Head>
            <Main>
              <Links>
                <Links_Child>
                  <FormLink to="/contact/" component={Link} scale={50}>お問い合わせフォーム</FormLink>
                </Links_Child>
                <Links_Child>
                  <TelList theme={props.theme}>
                    <TelList_Title variant="h3" component="h4" type="contrast" theme={props.theme}>お電話でのお問い合わせ</TelList_Title>
                    <TelList_Main>
                      {list.map((office, index) => {
                        return (
                          <TelList_Child key={index}>
                            <Tel type="contrast" href={'tel:' + office.tel.replace(/[^0-9]/g, '')}>
                              <Tel_Text variant="h3" component="span" lang="en" type="contrast">
                                <Tel_Area>{office.headquarter ? '本社' : office.area}：</Tel_Area><Tel_Number>{office.tel}</Tel_Number>
                              </Tel_Text>
                            </Tel>
                          </TelList_Child>
                        )
                      })}
                    </TelList_Main>
                  </TelList>
                </Links_Child>
              </Links>
            </Main>
          </Container>
        </Root>
      )
    }}
  />
)

export default ThemeConsumer(ContactLead)

const Root = styled(layouts.components.FullWidth)`
  position: relative;
`

const Container = styled(layouts.components.Container({
  base: { top: 48, bottom: 32 },
  tablet: { top: 40, bottom: 32 },
  mobile: { top: 40, bottom: 32 }
}))`
  position: relative;
`

const Background = styled(ParticleBackground)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Head = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: flex;
    align-items: center;
  }
`

const Main = styled.div`
`

const Title = styled(Typography)`
  @media ${mq.and(desktop, laptop, tabletL)} {
    width: 25%;
    flex-shrink: 0;
    padding-right: 40px;
  }
`

const Body = styled(Typography)`
  @media ${mq.and(tabletS, mobile)} {
    margin-top: 24px;
  }
`

const Links = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: flex;
    margin: 40px -8px 0;
  }
  @media ${mq.and(tabletS, mobile)} {
    margin-top: 40px;
  }
`

const Links_Child = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    width: 50%;
    padding: 0 8px;
  }
  @media ${mq.and(tabletS)} {
    margin-top: 16px;
  }
  @media ${mq.and(mobile)} {
    margin-top: 8px;
  }
`

const FormLink = styled(LeadButton1)`
  width: 100%;
  height: 100%;
`

const TelList = styled.div`
  width: 100%;
  height: 100%;
  padding: 32px 0 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${props => props.theme.scale[50]};
`

const TelList_Title = styled(Typography)`
  text-align: center;
  padding-bottom: 18px;
  border-bottom: 1px solid ${props => props.theme.scale[100]};
`

const TelList_Main = styled.ul`
  margin-top: 8px;
  text-align: center;
`

const TelList_Child = styled.li`
  margin-top: 16px;
`

const Tel = styled(LeadButton2)``

const Tel_Text = styled(Typography)`
  display: flex;
  align-items: center;
  @media ${mq.and(mobileS)} {
    font-size: 24px;
  }
`

const Tel_Area = styled.span`
  font-weight: normal;
  font-size: 0.6em;
  transform: translateY(0.06em);
`

const Tel_Number = styled.span``

const query = graphql`
  query ContactLeadQuery {
    list: allCompanyOfficeListYaml {
      edges {
        node {
          headquarter
          area
          tel
        }
      }
    }
  }
`
